<template>
  <main>
    <section class="section" style="padding-bottom: 0;">
      <nav class="breadcrumb is-medium" aria-label="breadcrumbs">
        <ul>
          <sqr-crumb name="broker" :bid="bid">{{$t('breadcrumb_broker', broker)}}</sqr-crumb>
          <sqr-crumb name="relations" :bid="bid" label="breadcrumb_relations"/>
          <sqr-crumb name="relation" :bid="bid" :rid="rid">{{$t('breadcrumb_relation', relation)}}</sqr-crumb>
          <sqr-crumb name="relation-emails" :bid="bid" :rid="rid" label="breadcrumb_relation_emails"/>
          <sqr-crumb name="relation-email" :bid="bid" :rid="rid" :query="$route.query" label="breadcrumb_relation_email"/>
        </ul>
      </nav>
    </section>
    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-12-mobile is-9-tablet is-6-desktop">
            <div class="field">
              <sqr-input-display label="email_template" :value="template && template.name"/>
            </div>
            <div class="field">
              <sqr-input-display label="email_to" :value="relation && (relation.name + ' <' + relation.email + '>')"/>
            </div>
            <div class="field">
              <sqr-input-text label="email_subject" v-model="subject" />
            </div>
            <div class="field">
              <sqr-input-textarea label="email_text" v-model="text" :rows="15"/>
            </div>
            <div class="field" v-if="attachments.length > 0">
              <label class="label">{{$t('email_attachments')}}</label>
              <div class="field" v-for="att in attachments" :key="att.fullPath">
                <sqr-input-display :value="att.name"/>
              </div>
            </div>
            <div class="field">
              <div class="buttons is-pulled-right">
                <sqr-button label="buttons_cancel" @click="$router.back()" color="light" size="medium"/>
                <sqr-button label="email_send" @click="send()" color="primary" size="medium" :is-loading="sending"/>
              </div>
              <sqr-error-note :error="sendError"/>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
const { compile } = require('handlebars');

import SqrInputDisplay from '@/sqrd/SqrInputDisplay';
import SqrInputText from '@/sqrd/SqrInputText';
import SqrInputTextarea from '@/sqrd/SqrInputTextarea';

import broker from './broker';
import relation from './relation';
import { mapActions, mapState } from 'vuex';
import buildRef from '@/utils/buildRef';

export default {
  name: 'RelationEmail',
  mixins: [broker, relation],
  components: {SqrInputDisplay, SqrInputText, SqrInputTextarea},
  computed: {
    ...mapState('emailsTemplate', {template: 'record', etLoading: 'loading', etError: 'error'}),
    ...mapState('emailAttachments', ['attachments'])
  },
  data () {
    return {
      subject: '',
      text: '',
      sending: false,
      sendError: null
    };
  },
  watch: {
    template: function(n) {
      this.refresh();
    },
    relation: function(n) {
      this.refresh();
    }
  },
  mounted () {
    this.etSub({
      path: ['brokers', this.bid, 'emails-templates'],
      id: this.$route.query.templateId
    });
  },
  methods: {
    ...mapActions('emailsTemplate', {etSub: 'sub'}),
    ...mapActions('emailAttachments', {attDownloadURLs: 'downloadURLs'}),
    async send() {
      try {
        this.sending = true;
        this.sendError = null;
        await this.attDownloadURLs();
        const ref = buildRef(['brokers', this.bid, 'emails']);
        const rel = this.relation;
        const to = `${rel.givenName} ${rel.familyName} <${rel.email}>`;
        const doc = {
          relationId: this.rid,
          to,
          message: {
            subject: this.subject,
            text: this.text,
            attachments: this.attachments.map(att => ({
              filename: att.name,
              href: att.url
            }))
          }
        };
        await ref.add(doc);
        this.$router.push({name: 'relation-emails', params: {bid: this.bid, rid: this.rid}});
      } catch (error) {
        this.sendError = error;
      } finally {
        this.sending = false;
      }
    },
    refresh () {
      if (!this.template) return;
      if (!this.relation) return;

      this.subject = compile(this.template.subject)(this.relation);
      this.text = compile(this.template.text)(this.relation);
    }
  }
}
</script>
